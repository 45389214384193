const data = {
  title: 'Especialistas en Viajes',

  elements: [
    {
      id: '1',
      url: 'http://vuelos.turista.com.mx',
      title: 'Vuelos',
    },
    {
      id: '2',
      url: 'https://www.turista.com.mx/rentaAutos',
      title: 'Renta de Autos',
    },
  ],
}

export default data
